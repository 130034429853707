/* Token Group api details */
.api-details-container {
  background: #202833;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.api-details-container code {
  background: #252c39;
  padding: 8px;
  border-radius: 4px;
  display: block;
  color: #d1d8e0;
  margin-top: 5px;
}

.api-detail-header {
  background: #343a40; /* Un gris moderne et élégant */
  padding: 10px 15px;
  border-radius: 4px;
  font-weight: bold;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
}

.api-detail-header:hover {
  background: #495057; /* Légère variation de couleur au survol */
}

.api-detail-header .api-endpoint-url {
  /* nice and modern violet/pink/mauve color */
  /* color:  */
}

span.api-endpoint-method {
  background: #4b6584;
  padding: 2px 5px;
  border-radius: 4px;
  margin-right: 5px;
  color: #fff;
}

span.api-endpoint-method.get {
  background: #20bf6b;
}

span.api-endpoint-method.post {
  background: #4b7bec;
}

span.api-endpoint-method.put {
  background: #8854d0;
}

span.api-endpoint-method.delete {
  background: #eb3b5a;
}

.api-detail-body {
  padding: 15px;
  border-left: 3px solid #4b6584;
  margin-bottom: 15px;
  background: #252c39;
  border-radius: 4px;
}

.api-details-container .api-instructions,
.api-details-container .api-response {
  display: flex;
  flex-direction: column;
}

.api-details-container .api-instructions {
  padding-right: 7.5px;
}

.api-details-container .api-response {
  padding-left: 7.5px;
}

.api-details-container .api-instructions > *:last-child,
.api-details-container .api-response > *:last-child {
  flex-grow: 1;
}

.api-endpoint .request-title-part,
.api-response .request-title-part,
.api-instructions .request-title-part,
.api-details-container .request-title-part {
  font-weight: bold;
}

.api-endpoint code,
.api-response pre,
.api-instructions pre {
  background: #2d3436;
  padding: 8px;
  border-radius: 4px;
  display: block;
  color: #d1d8e0;
  margin-top: 5px;
}

/* Style pour le mode clair */

.api-details-container.lite {
  background: #fff;
  color: #000;
}

.api-details-container.lite .api-detail-header {
  background: #f8f9fa;
  color: #333;
}

.api-details-container.lite .api-detail-body {
  background: #f5f5f5;
  color: #000;
}

.api-details-container .endpoint-details {
  display: flex;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
  /* justify-content: space-between; */
  /* gap: 20px; */
}

.api-details-container .endpoint-details > div {
  /* width: 50%; */
  box-sizing: border-box;
  max-width: 50%;
  flex-grow: 1;
}

.io-description-container {
  margin-bottom: 20px;
  /* Epurate, modern style for body doc */
  background: #202833;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2 px 6px rgba(0, 0, 0, 0.2);
  word-break: break-word;
}

.io-description-container h4 {
  margin-bottom: 15px;
}

.io-description-container p {
  margin-bottom: 10px;
  line-height: 1.5;
}

.io-description-field {
  font-weight: bold;
}

.io-description-container code {
  background: #252c39;
  padding: 8px;
  border-radius: 4px;
  display: block;
  color: #d1d8e0;
  margin-top: 5px;
}

/* Style for light mode */
.api-details-container.lite .io-description-container {
  background: #fff;
  color: #777;
}

.api-details-container.lite .io-description-container code {
  background: #f5f5f5;
  color: #333;
}

.code-example-container {
  margin-bottom: 20px;
  /* Epurate, modern style for body doc */
  background: #202833;
  color: #fff;
  padding: 15px;
  /* border-radius: 8px; */
  margin-bottom: 20px;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
  word-break: break-word;
}

.code-example-container .cm-editor {
  padding: 15px 10px;
}

/* lite mode */
.api-details-container.lite .code-example-container {
  background: #f5f5f5;
  color: #777;
}

.code-example-container .code-mirror-cutter {
  border-radius: 5px;
  overflow: hidden;
}

.code-example-container .code-mirror-cutter.loading {
  min-height: 350px;
  background: #f5f5f5;

  animation: backgroundSmoothColorChange 500ms infinite alternate;
}

@keyframes backgroundSmoothColorChange {
  0% {
    background: #f5f5f5;
  }
  100% {
    background: #e0e0e0;
  }
}
