.api-response-visualizer-container {
  background: rgba(0, 0, 0, 0.1);
  padding: 10px 0px 0px 10px;
  border-radius: 5px;
}

.api-response-visualizer {
  font-family: monospace;
}

.api-response-content {
  padding-left: 20px;
  max-height: 300px;
  overflow-y: auto;
}

.api-response-element {
  padding-left: 20px;
}

.api-response-brackets {
  color: #a52a2a;
  cursor: pointer;
  /* position: relative; */
}

.api-response-brackets:hover {
  background-color: #f0f0f0;
}

.api-response-key {
  cursor: pointer;
  color: #a52a2a;
  /* position: relative; */
}

.api-response-key:hover {
  color: #00008b;
}

.api-response-value {
  cursor: pointer;
  color: #00008b;
  /* position: relative; */
}

.api-response-value:hover {
  color: #a52a2a;
}

.element-options-popover .popover-arrow {
  display: none !important;
}

/* element-options-popover should be large with a dark background, modern and epurated */
.element-options-popover {
  background-color: #333 !important;
  color: #fff !important;
  border-radius: 5px !important;
  padding: 10px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  /* width: 300px; */
  max-width: 200px;
  min-width: 200px;
  padding-top: 0;
  padding-bottom: 0;
  /* font-size: 14px;
  line-height: 1.5; */
}

.element-options-popover .popover-header {
  background-color: #333 !important;
  color: #fff !important;
  border-bottom: 1px solid #444 !important;
  padding: 10px 15px !important;
  border-radius: 5px 5px 0 0 !important;
}

.element-options-popover .popover-body p:last-child {
  margin-bottom: 0;
}

.element-options-popover p,
.element-options-popover a,
.element-options-popover h1,
.element-options-popover h2,
.element-options-popover h3,
.element-options-popover h4,
.element-options-popover h5,
.element-options-popover h6 {
  color: #fff !important;
}
