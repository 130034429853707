.method-rules-script-editor_container {
  min-height: 300px;
  border: 1px solid var(--gray-color);
  border-radius: 4px;
  margin-bottom: 20px;
  background-color: var(--bg-dark-modal-body-1);
}

.method-rules-script-editor_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: var(--bg-dark-modal-header);
  border-bottom: 1px solid var(--gray-color);
}

/* Style pour CodeMirror (si besoin de surcharger) */
.method-rules-script-editor_container .cm-theme {
  --cm-background: var(--bg-dark-modal-body-1);
  --cm-color: var(--white-color);
  --cm-gutterBackground: var(--bg-dark-item-2);
  --cm-gutterBorder: var(--gray-color);
}

/* Assurez-vous que le thème sublime est bien appliqué et ajustez selon vos besoins */
.method-rules-script-editor_container .CodeMirror {
  font-family: "Monaco", "Menlo", "Ubuntu Mono", "Consolas", "source-code-pro",
    monospace;
  font-size: 14px;
  height: auto;
  color: var(--white-color);
}

.method-rules-script-editor_container .CodeMirror-lines {
  padding: 10px 0; /* Ajustement de l'espacement interne pour les lignes */
}

/* Couleurs personnalisées pour les tokens JavaScript (facultatif) */
.method-rules-script-editor_container .cm-s-sublime .cm-keyword {
  color: var(--violet-color);
}
.method-rules-script-editor_container .cm-s-sublime .cm-string {
  color: var(--success-color);
}
.method-rules-script-editor_container .cm-s-sublime .cm-number,
.cm-s-sublime .cm-atom {
  color: var(--orange-color);
}
.method-rules-script-editor_container .cm-s-sublime .cm-variable {
  color: var(--info-color);
}
.method-rules-script-editor_container .cm-s-sublime .cm-variable-2 {
  color: var(--warning-color);
}
.method-rules-script-editor_container .cm-s-sublime .cm-def {
  color: var(--primary-color);
}
.method-rules-script-editor_container .cm-s-sublime .cm-type {
  color: var(--success-color);
}
.method-rules-script-editor_container .cm-s-sublime .cm-comment {
  color: var(--gray-color);
}
.method-rules-script-editor_container .cm-s-sublime .cm-operator {
  color: var(--pink-color);
}

.script-select-api-transformer {
  margin-bottom: 20px;
  background-color: transparent;
  color: var(--white-color);
  border: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.script-select-api-transformer option {
  font-size: 14px !important;
  font-family: "Montserrat", sans-serif;
}
