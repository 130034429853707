/* Styles pour BlockType */
.block-type .badge-type {
  background-color: #6c757d !important; /* Gris foncé pour une visibilité accrue */
  color: white; /* Texte blanc pour contraster avec le fond sombre */
  padding: 0.5em 0.75em; /* Espacement interne pour donner de la respiration */
  border-radius: 0.25em; /* Bords arrondis pour un look doux */
  font-weight: bold; /* Rendre le texte en gras pour attirer l'attention */
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2); /* Ombre portée pour un effet de profondeur */
  margin-right: 0.5em; /* Marge à droite pour séparer des autres éléments si nécessaire */
  transition: background-color 0.3s ease-in-out; /* Transition douce pour les interactions */
  font-size: 1em; /* Taille de la police légèrement augmentée pour la lisibilité */
}

.block-type .badge-type.with-details {
  cursor: help;
}

/* Style du Tooltip pour qu'il soit cohérent avec le badge */
.block-type .tooltip-inner {
  background-color: #343a40; /* Fond plus sombre que le badge pour la distinction */
  color: #f8f9fa; /* Texte clair pour la lisibilité */
  border-radius: 0.25em; /* Bords arrondis pour le style */
  padding: 0.5em; /* Espacement interne pour l'aisance visuelle */
  font-size: 0.9em; /* Légère réduction de la taille de la police pour la subtilité */

  /* \n should be replaced with a newline character */
  white-space: pre-line; /* Permettre l'affichage des sauts de ligne */
}

/* Transition douce au survol du badge */
.block-type .badge-type:hover {
  background-color: #5a6268; /* Assombrir le fond au survol */
}
