.creaxys-deployments .deployments-header {
  padding: 20px;
  background-color: #f4f4f4;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.creaxys-deployments button {
  padding: 10px 15px;
  background-color: #4b7bec;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.creaxys-deployments button:hover {
  background-color: #3a61c9;
}

.deployments-header button {
  padding: 8px 12px; /* Réduire la taille du padding */
  font-size: 0.9em; /* Diminuer légèrement la taille du texte */
  margin-right: 10px; /* Ajouter un espacement entre les boutons */
}

.deployments-header {
  justify-content: flex-start; /* Aligner les éléments à gauche */
  align-items: center;
}
