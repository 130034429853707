/* Styles pour ApiComponentsList */
.components-list {
  background-color: #23324d;
  color: #fff;
  padding: 10px;
  overflow-y: auto;
  max-height: 100%;
  list-style: none;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.list-item {
  padding: 8px 10px;
  margin: 2px 0;
  /* background-color: #2a2d32; */
  transition: all 0.1s ease;
  cursor: pointer;
  font-size: 0.9em;
}

.list-item.is-dragging {
  opacity: 0.5;
}

.list-item:hover {
  border-left: 3px solid #4b7bec;
  background-color: #3a3f45;
  /* border-left-color: #4b7bec; */
}

/* Styles pour indiquer la position du survol */
.list-item.hover-top {
  border-top: 3px solid #26de81;
  border-left: none;
  width: 100%;
  /* transform: translateY(3px); */
}

.list-item.hover-bottom {
  border-bottom: 3px solid #26de81;
  border-left: none;
  width: 100%;
  /* transform: translateY(-3px); */
}

.list-item.hover-middle {
  background-color: #3a3f45;
  border-left: 3px solid #4b7bec;
  width: 100%;
  transform: scale(1.07);
}

.children-list {
  padding-left: 15px;
}
