.middleware-details-container {
  background: #202833; /* Fond sombre */
  color: #aaa; /* Couleur du texte plus foncée */
  padding: 15px;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.detail-item {
  background: #252c39; /* Fond pour chaque élément de détail */
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9em; /* Taille de texte plus petite */
}

/* son parent a un background blanc, donc faire un texte ni trop clair, ni trop foncé */
.detail-item span {
  color: #999;
}

/* Styles pour le mode clair */
.middleware-details-container.lite {
  background: #f0f0f0; /* Fond clair */
  color: #666; /* Texte plus foncé en mode clair */
}

.middleware-details-container.lite .detail-item {
  background: #fff;
  color: #333;
}

.cors-handler-details .detail-item {
  margin-bottom: 10px;
}

.cors-handler-details .detail-list {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.cors-handler-details .detail-list-item {
  background: #252c39; /* Fond pour chaque élément de la liste */
  padding: 6px 12px;
  border-radius: 4px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* color: #ccc; Texte un peu plus clair */
  color: #999; /* Texte un peu plus clair */
}

.cors-handler-details .detail-list-item span {
  /* color: #d1d8e0; Texte plus clair pour une meilleure lisibilité */
  color: #999; /* Texte un peu plus clair */
}

/* Style pour le mode clair */
.cors-handler-details.lite .detail-list-item {
  background: #fff;
  color: #333;
}
