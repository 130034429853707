.system-designer-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.system-designer-content-column {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
