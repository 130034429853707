.deployment-list {
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  flex-grow: 1;
}

.deployment-item {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  padding: 10px;
  margin-bottom: 10px;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s, transform 0.3s;
  position: relative;
  z-index: 1;
}

.deployment-item:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px); /* Effet de léger soulèvement au survol */
  z-index: 2;
}

.deployment-item > *:not(:last-child) {
  width: calc((100% - 100px * 4) / 2);
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  padding-right: 10px;
  padding-left: 10px;
  box-sizing: border-box;
}

.deployment-item .deployment-name {
}

.deployment-item .deployment-status,
.deployment-item .deployment-resource,
.deployment-item .deployment-action-dropdown,
.deployment-item .deployment-type {
  width: 100px;
  text-align: center;
}

.deployment-item span {
  margin-right: 10px;
  font-weight: 500;
}

.deployment-item .deployment-status {
  padding: 2px 6px; /* Espace intérieur réduit */
  border-radius: 12px;
  color: white;
  font-weight: bold;
  font-size: 0.75rem; /* Taille de police réduite pour le statut */
}

.status-pending {
  background-color: #f0ad4e;
}
.status-active {
  background-color: #5cb85c;
}
.status-inactive {
  background-color: #999;
}
.status-error {
  background-color: #d9534f;
}

.deployment-item .deployment-url {
  /* lite smooth grey url modern */
  color: #999;
}

.deployment-action {
  border: none;
  background: none;
  cursor: pointer;
}

/* Icônes pour les types de ressources */
.deployment-resource svg {
  color: #4b7bec;
}

.deployment-action-dropdown {
  position: relative;
  cursor: pointer;
}

.deployment-action-icon {
  color: #333;
  transition: color 0.3s;
}

.deployment-action-dropdown:hover .deployment-action-icon {
  color: #4b7bec;
}

.deployment-dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
  border-radius: 4px;
  overflow: hidden;
}

.deployment-action-dropdown:hover .deployment-dropdown-content {
  display: block;
}

.deployment-dropdown-content a {
  color: #333;
  padding: 8px 12px; /* Réduction de la hauteur */
  text-decoration: none;
  display: block;
  font-size: 0.85rem; /* Taille de police plus petite */
  transition: background-color 0.3s;
}

.deployment-dropdown-content a:hover {
  background-color: #f2f2f2; /* Couleur de fond plus douce au survol */
}

.deployment-resource svg {
  /* ... */
}

/* Modification de l'espacement et de la taille des icônes */
.deployment-action-icon {
  font-size: 1rem; /* Taille de police réduite pour l'icône */
  /* padding: 8px; Espace autour de l'icône */
  color: #333;
  transition: color 0.3s;
}
