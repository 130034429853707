.creaxys-api-details-container {
  background: #202833;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.creaxys-api-details-container h4 {
  margin-bottom: 15px;
}

.creaxys-api-details-container p {
  margin-bottom: 10px;
  line-height: 1.5;
}

.creaxys-api-details-container .api-endpoint span,
.creaxys-api-details-container .api-headers span {
  font-weight: bold;
}

.creaxys-api-details-container code {
  background: #252c39;
  padding: 8px;
  border-radius: 4px;
  display: block;
  color: #d1d8e0;
  margin-top: 5px;
}

/* Style for light mode */
.creaxys-api-details-container.lite {
  background: #fff;
  color: #000;
}

.creaxys-api-details-container.lite .api-endpoint code,
.creaxys-api-details-container.lite .api-headers code {
  background: #f5f5f5;
  color: #000;
}
