.route-parent-item .route-item {
  margin-bottom: 0;
}

.route-parent-item.has-components .route-item,
.route-parent-item.has-components .endpoint-item,
.route-parent-item.has-components .middleware-item {
  /* border-bottom: 1px solid #3b4045; */
  /* lighter border bottom try 1*/
  /* border-bottom: 3px solid #555; */
  /* lighter border-bottom try 2 */
  border-bottom: 3px solid #555;
}

/* first route parent with nor border-radius bottom left and bottom right when has components */
.route-parent-item.has-components:first-child .route-item {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

/* remove border radius for all other components */
.route-parent-item.has-components .route-item,
.route-parent-item.has-components .endpoint-item,
.route-parent-item.has-components .middleware-item {
  border-radius: 0;
}

.route-url-input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: none;
  border-bottom: 1px solid #555; /* subtle border color */
  background-color: transparent; /* make input background transparent */
  color: #ddd; /* light text color for dark backgrounds */
  font-size: 1rem;
}

.route-url-input::placeholder {
  color: #aaa; /* even lighter color for placeholder text */
}

.route-url-input:focus {
  outline: none;
  border-bottom: 1px solid #26de81; /* highlight color for focus */
}
