.creaxys-deployments {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.create-deployment-form-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  background-color: #f4f4f4;
  border-radius: 8px 8px 0 0;
}

.create-deployment-form-container {
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  margin-top: -8px; /* Overlap with header */
}

.create-deployment-form {
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 600px; /* Limiter la largeur */
  margin: auto;
}

.create-deployment-form label {
  font-weight: 500;
}

.create-deployment-form input,
.create-deployment-form select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.create-deployment-form button {
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px 15px;
  font-size: 0.9rem;
}

.create-deployment-form button[type="button"] {
  background-color: #eaeaea;
  color: #333;
}

.create-deployment-form button[type="submit"] {
  background-color: #4b7bec;
  color: white;
}

.token-group-selection {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

.token-group-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  background-color: #f9f9f9; /* Couleur de fond légère */
  border: 1px solid #eaeaea; /* Bordure subtile */
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Ombre douce pour la profondeur */
  transition: box-shadow 0.3s, border-color 0.3s;
}

.token-group-block:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Ombre plus prononcée au survol */
}

.token-group-block.configured {
  border-color: #a5d8a5; /* Vert doux pour indiquer 'configuré' */
}

.token-group-name {
  font-size: 1rem;
  color: #333; /* Couleur de texte foncée pour le contraste */
}

.token-group-select {
  padding: 8px 12px;
  border: 1px solid #dcdcdc; /* Bordure claire pour le select */
  border-radius: 8px;
  background-color: white;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05); /* Ombre intérieure légère */
}

.token-group-select:focus {
  border-color: #a5d8a5; /* Mise en évidence lors de la sélection */
}
