.liteTraining .textarea-1 {
  min-height: 0px !important;
  height: 250px !important;
}

.liteTraining .btn-auto {
  width: auto;
}

/* TextareaWordsSelectable */
.TextareaWordsSelectable {
  /* padding: 20px 0px; */
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  /* border-radius: 10px; */
  /* overflow-x: hidden; */
  overflow-y: auto;
  height: 250px;
}

.TextareaWordsSelectable .height-referencer {
  position: relative;
  width: 100%;
  height: fit-content;
}

/* bootstrap textarea */
.TextareaWordsSelectable .hidden-input {
  position: absolute !important;
  /* top: -1000px !important;
  left: -1000px !important; */
  /* height: 250px; */
  min-height: 100% !important;
  /* width: 100%; */
  z-index: 2;
  background-color: rgba(0, 0, 0, 0);
  border: solid 1px rgba(0, 0, 0, 0);
  outline: none;
  color: rgba(0, 0, 0, 0);
  word-break: break-all;
  resize: none;
  caret-color: rgba(0, 0, 0, 0.9);
}

.TextareaWordsSelectable .hidden-input:focus {
  outline: none;
  background-color: rgba(0, 0, 0, 0);
  border: solid 1px rgba(0, 0, 0, 0);
  outline: none;
  color: rgba(0, 0, 0, 0);
  caret-color: rgba(0, 0, 0, 0.9);
}

/* Must looks like a bootstrap text-area */
.TextareaWordsSelectable .fake-textarea {
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  /* border-radius: 10px; */
  /* overflow-x: hidden; */
  overflow-y: auto;
  cursor: text;
  min-height: 250px !important;
  height: 100% !important;
  /* color: rgba(0, 0, 0, 0); */
  /* height: 200px;
  width: 100%;
  overflow: auto;
  border: 1px solid #ced4da; */
  /* padding: 5px; */
  /* padding: 0.47rem 0.75rem;
  border-radius: 5px;
  background-color: #fff;
  line-height: 1.5;
  color: #495057;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  z-index: 1; */

  /* padding: 0.47rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5; */
}
