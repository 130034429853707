.auth-checker {
  color: #ffffff;
  font-size: 0.9em;
}

.auth-checker .add-token-group {
  display: flex;
  align-items: center;
  gap: 5px;
}

.auth-checker .add-token-group select,
.auth-checker .add-token-group button {
  padding: 5px;
  border: 1px solid #778ca3;
  border-radius: 4px;
  background-color: #1e2125;
  color: white;
  cursor: pointer;
  margin-bottom: 0;
}

.auth-checker .token-groups-list {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.auth-checker .token-group-item {
  display: flex;
  align-items: center;
  background-color: #2a2d32;
  padding: 5px;
  border-radius: 4px;
  gap: 5px;
}

.auth-checker .token-group-item span {
  flex-grow: 1;
}

.auth-checker .token-group-item select {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #778ca3;
  background-color: transparent;
  margin-bottom: 0;
}

.auth-checker .token-group-item button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #eb3b5a;
}
