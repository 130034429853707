.middleware-item {
  background-color: #23324d;
  padding: 10px;
  border-radius: 5px;
  /* margin-bottom: 10px; */
  color: white;
}

.middleware-item input,
.middleware-item select {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background-color: transparent;
  color: white;
}

.middleware-item select {
  cursor: pointer;
}

/* <>
<div className="middleware-header">
  <div className="middleware-back-button" onClick={clearMiddlewareType}>
    <FaArrowLeft />
  </div>
  <div className="middleware-name">{copyComponent.name}</div>
  <div className="middleware-type">{copyComponent.middlewareType}</div>
</div>
</> */

/* Above should be row aligned and nice and smooth small but readable text */
.middleware-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.middleware-header .middleware-back-button {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.middleware-header .middleware-back-button svg {
  font-size: 1.2em;
}

.middleware-header .middleware-name {
  font-size: 1.2em;
}

.middleware-header .middleware-type {
  font-size: 0.9em;
  color: #bfbfbf;
}

.delete-button-container {
  display: flex;
  justify-content: flex-end;
}

.delete-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #eb3b5a;
}
