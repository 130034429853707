/* @import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&family=Lato:wght@400;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Open+Sans:wght@400;700&display=swap");

.api-model-documentation-modal .modal-dialog {
  max-width: 95vw; /* Largeur maximale du modal */
  height: 90vh; /* Hauteur du modal */
  margin: 2.5vh auto; /* Centre le modal verticalement avec un peu d'espace en haut et en bas */
  display: flex;
  flex-direction: column;
}

.api-model-documentation-modal .modal-content h1,
.api-model-documentation-modal .modal-content h2,
.api-model-documentation-modal .modal-content h3,
.api-model-documentation-modal .modal-content h4,
.api-model-documentation-modal .modal-content h5,
.api-model-documentation-modal .modal-content h6 {
  font-family: "Roboto" !important; /* Police de caractères pour le texte */
}

.api-model-documentation-modal .modal-content p,
.api-model-documentation-modal .modal-content ul,
.api-model-documentation-modal .modal-content a,
.api-model-documentation-modal .modal-content span {
}

.api-model-documentation-modal .modal-content {
  background-color: #1e2a36; /* Couleur de fond du modal */
  color: #fff; /* Couleur du texte */
  border-radius: 8px; /* Rayon de la bordure */
  border: none; /* Suppression de la bordure */
  height: calc(100vh - 28px - 28px);
  display: flex;
  flex-direction: column;
}

.api-model-documentation-modal .modal-body {
  background-color: #1e2a36; /* Couleur de fond du corps */
  padding: 20px; /* Espace intérieur du corps */
  overflow-y: auto; /* Permet le défilement si le contenu est trop long */
  flex-grow: 1; /* S'étend pour remplir l'espace disponible */
}

.api-model-documentation-modal .modal-header {
  border-bottom: 1px solid #2a3f55; /* Couleur de la bordure inférieure de l'en-tête */
  background-color: #23324d; /* Couleur de fond de l'en-tête */
  padding: 15px; /* Espace intérieur de l'en-tête */
}

.api-model-documentation-modal .modal-title {
  color: #d4af37; /* Couleur du titre */
}

.api-model-documentation-modal .modal-footer {
  border-top: 1px solid #2a3f55; /* Couleur de la bordure supérieure du pied de page */
  background-color: #23324d; /* Couleur de fond du pied de page */
  padding: 15px; /* Espace intérieur du pied de page */
}

.api-model-documentation-modal .btn-close {
  color: #fff; /* Couleur du bouton de fermeture */
}

.api-model-documentation {
  background-color: transparent; /* Pour éviter un fond blanc */
  color: #fff; /* Pour la lisibilité du texte */
  padding: 20px;
  height: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}

.doc-header {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.doc-header button {
  background: none;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: #d4af37; /* Couleur du texte des boutons désactivés pour une meilleure visibilité */
}

.doc-header button.active {
  background-color: #23324d;
  color: #fff;
}

.doc-content {
  display: flex;
  /* flex-grow: 1; */
  height: calc(100% - 40px);
}

/* Styles pour les en-têtes de sections et le texte dans ApiModelDocumentation */
.api-model-documentation h1,
.api-model-documentation h2,
.api-model-documentation h3,
.api-model-documentation p {
  /* color: #d4af37; Couleur adaptée pour les titres et le texte */
}
