/* background: linear-gradient(145deg, #1c2541 0%, #3a506b 100%); */

/* color: #88a2d2; */
/* color: #c0d4ed; */

.launchlite-overview {
  height: calc(100vh - 60px - 53px);
  background: linear-gradient(to right, #0f1825, #1f2f45);
  color: #ffffff;
  display: flex;
  align-items: flex-start; /* Changed to flex-start to align content to the top */
  justify-content: center;
  text-align: left;
  padding: 50px 150px 50px 150px; /* Adjusted padding to account for left navigation bar */
  width: calc(100vw - 250px);
  overflow-y: auto;
}

.launchlite-overview .content {
  max-width: 1200px;
  width: 100%;
}

.launchlite-overview h2,
.launchlite-overview h3 {
  color: var(
    --primary-color
  ); /* Assuming --primary-color is defined elsewhere in your root or :root CSS */
  opacity: 0.95;
}

.launchlite-overview h2 {
  font-size: 2rem; /* Reduced size for better fit */
  margin-bottom: 0.75rem; /* Adjusted margin for spacing */
}

.launchlite-overview p {
  font-size: 1rem; /* Adjusted font size for readability */
  line-height: 1.6;
  color: #a0b9d9; /* Lighter color for better readability */
}

.launchlite-overview .features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.launchlite-overview .feature h3 {
  font-size: 1.25rem; /* Adjusted size for hierarchy */
  color: #a0b9d9; /* Lighter color for better readability */
  margin-bottom: 0.5rem; /* Added margin for spacing */
}

.launchlite-overview .feature p {
  color: #ccd7e4; /* Lighter color for better readability */
}

.launchlite-overview .benefits {
  margin-top: 2rem; /* Adjusted margin for spacing */
}

.launchlite-overview .benefits h3 {
  font-size: 1.5rem; /* Adjusted size for hierarchy */
  margin-bottom: 1rem; /* Adjusted margin for spacing */
}

.launchlite-overview .benefits ul {
  list-style: none;
  padding-left: 0;
}

.launchlite-overview .benefits ul li {
  font-size: 1rem; /* Adjusted font size for readability */
  margin-bottom: 0.5rem; /* Adjusted margin for spacing */
  line-height: 1.6;
  color: #ccd7e4; /* Lighter color for better readability */
}

.launchlite-overview .mention-text {
  font-size: 0.9rem;
  color: #ccd7e4; /* Lighter color for better readability */
  margin-top: 2rem;
}
