.typing-form {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.typing-form .CodeMirror {
  border: 1px solid #ccc;
  font-size: 14px;
  height: auto;
  min-height: 100px;
}
