.construction-zone-navbar {
  background-color: #23324d;
  padding: 10px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid #1a2a3a;
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  color: #d4af37;
  text-decoration: none;
  font-size: 0.9em;
  padding: 5px 10px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.navbar-links li:hover {
  background-color: #3a3f45;
}

.navbar-links li.active {
  background-color: #4b7bec;
}

.navbar-links li.active:hover {
  background-color: #3a61c9;
}

/* .construction-zone-navbar {
  background-color: #1a2b3c;
  color: #ffffff;
  padding: 10px 20px;
  display: flex;
  justify-content: start;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.navbar-links li {
  cursor: pointer;
  transition: color 0.3s ease;
}

.navbar-links li:hover {
  color: #ffdd57;
} */

/* .LaunchLite .construction-zone-navbar {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.LaunchLite .navbar-link {
  padding: 5px 10px;
  background-color: #23324d;
  border-radius: 3px;
  color: #ffffff;
  font-size: 0.8em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.LaunchLite .navbar-link:hover {
  background-color: #3a3f45;
}

.LaunchLite .construction-zone-navbar .navbar-link.active {
  background-color: #4b7bec;
}

.LaunchLite .construction-zone-navbar .navbar-link.active:hover {
  background-color: #3a61c9;
} */

/* .construction-zone-navbar {
  background-color: #23324d;
  padding: 10px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid #1a2a3a;
}

.navbar-link {
  color: #d4af37;
  text-decoration: none;
  font-size: 0.9em;
  padding: 5px 10px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.navbar-link:hover {
  background-color: #3a3f45;
}

.navbar-link.active {
  background-color: #4b7bec;
}

.navbar-link.active:hover {
  background-color: #3a61c9;
} */
