.api-keys-modal .modal-dialog {
  /* max-width: 600px; */
  border: none;
}

.api-keys-modal .modal-content {
  background-color: var(--light-color);
  color: var(--dark-color);
  border-radius: 10px;
  border: none;
  min-height: 550px;
}

.api-keys-modal .modal-header {
  background: var(--bg-dark-nav-1); /* Noir moderne */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 15px 20px;
  border: none;
}

.api-keys-modal .modal-header h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  color: var(--white-color);
}

.api-keys-modal .modal-header button {
  background: none;
  border: none;
  color: var(--white-color);
  font-size: 1.5rem;
  cursor: pointer;
}

.api-keys-modal .modal-header button:hover {
  color: var(--primary-color);
}

.api-keys-modal .modal-tabs {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  margin: 0;
  background: var(--bg-dark-item-2); /* Noir fondu */
}

.api-keys-modal .modal-tabs button {
  background: none;
  border: none;
  color: var(--gray-color);
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: color 0.3s;
}

.api-keys-modal .modal-tabs button.active,
.api-keys-modal .modal-tabs button:hover {
  color: var(--white-color);
}

.api-keys-modal .modal-body {
  background-color: var(--light-color);
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.api-keys-modal .modal-body .modal-tab-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}

.api-keys-modal .modal-body .modal-tab-content .api-keys-actions {
  display: flex;
  justify-content: end;
}

.api-keys-modal .modal-body .modal-tab-content .api-keys-list {
  flex-grow: 1;
}

.api-keys-modal .modal-body p {
  color: var(--dark-color);
  margin: 10px 0;
}

.api-keys-modal .modal-body .link-button {
  background-color: transparent;
  color: #4b7bec;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: color 0.3s, text-decoration 0.3s;
  text-decoration: none;
  display: inline-block;
  font-weight: bold;
}

.api-keys-modal .modal-body .link-button:hover {
  text-decoration: underline;
}

.api-keys-modal .api-keys-list {
  overflow-x: auto;
  /* margin-top: 20px; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  max-height: 50vh;
  position: relative;
  min-height: 50vh;
}

.api-keys-modal table {
  width: 100%;
  /* border-collapse: separate; */
  border-spacing: 0;
  background-color: var(--light-color);
  border-collapse: collapse; /* Évite les doubles bordures */
  table-layout: fixed; /* Garantit une largeur fixe des colonnes */
  /* Lite box shadow just to avoid using border and having nice transition between this element and his parent */
}

/* position sticky to header */
.api-keys-modal tr:first-child {
  position: sticky;
  top: 0;
  background-color: var(--bg-dark-item-2);
  color: var(--white-color);
}

/* text-align right for each last column of each row */
.api-keys-modal tr td:last-child {
  text-align: right;
}

.api-keys-modal td .copy-icon {
  color: #888;
  cursor: pointer;
  margin-left: 10px;
}

/* th.field-status, */
th.field-actions,
/* td.field-status, */
td.field-actions {
  width: 80px;
}

.api-keys-modal th,
.api-keys-modal td {
  text-align: center;
  padding: 12px 15px;
  /* border-bottom: 1px solid var(--gray-dark-color); */
  word-break: break-word;
}

.api-keys-modal th {
  background-color: var(--bg-dark-item-2);
  color: var(--white-color);
  font-weight: bold;
}

.api-keys-modal td {
  color: var(--dark-color);
}

.api-keys-modal tr:nth-child(even) {
  background-color: #fff;
}

.api-keys-modal tr:hover {
  /* background-color: var(--gray-dark-color);
  color: var(--white-color); */
}

.api-keys-modal .active-icon {
  color: var(--success-color);
  font-size: 1.2rem;
}

.api-keys-modal .unactive-icon {
  color: var(--danger-color);
  font-size: 1.2rem;
}

.api-keys-modal .api-keys-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.api-keys-modal .link-button {
  margin-left: 10px;
  color: var(--blue-color);
  font-size: 0.9rem;
}

.api-keys-modal .link-button:hover {
  text-decoration: underline;
}
