.rate-limiter {
  color: #ffffff;
  font-size: 0.9em;
}

.rate-limiter .rate-limiter-config {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}

.rate-limiter .rate-limiter-config input,
.rate-limiter .rate-limiter-config select {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #778ca3;
  background-color: #1e2125;
  color: white;
  margin-bottom: 0;
}

.rate-limiter .rate-limiter-config button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #eb3b5a;
}

.rate-limiter .add-rate-limiter {
  display: flex;
  justify-content: center;
}

.rate-limiter .add-rate-limiter button {
  background-color: transparent;
  border: 1px solid #778ca3;
  padding: 5px;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}
