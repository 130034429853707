/* Ajoutez d'autres styles nécessaires ici */

.rest-api-doc {
  display: flex;
  height: 100%; /* S'étend sur toute la hauteur du .modal-body */
  width: 100%;
  position: relative;
}

.sidebar {
  width: 250px;
  min-width: 250px;
  /* height: 100%; */
  background: #2a2d32;
  color: #fff;
  overflow-y: auto; /* Pour la barre de défilement si nécessaire */
  padding: 10px;
  /* as parent is a flex, make this element take all space in height */
}

.sidebar-item {
  cursor: pointer;
  padding: 5px 10px;
  margin: 5px 0;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.sidebar-item:hover,
.sidebar-item.active {
  background-color: #3a3f45;
}

.child-components {
  padding-left: 20px;
}

.rest-api-doc .content {
  position: relative;
  flex-grow: 1;
  overflow-y: auto; /* Pour la barre de défilement si nécessaire */
  padding: 20px;
  background-color: #f4f4f4;
  color: #333;
  padding-top: 65px;
}

.content .component-details {
  margin-bottom: 40px;
}

.content .component-details h3 {
  margin-bottom: 10px;
}

.content
  .component-details
  .endpoint-details-container
  .endpoint-details
  .request-side
  .request-line
  .method.get {
  background: #20bf6b;
}

.content
  .component-details
  .endpoint-details-container
  .endpoint-details
  .request-side
  .request-line
  .method.post {
  background: #4b7bec;
  color: #fff;
}
.rest-api-doc .doc-actions-header {
  margin-bottom: 20px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 20px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  z-index: 1;
  width: calc(100% - 250px);
  color: #888;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
}

.rest-api-doc .doc-actions-header .download-postman-btn {
  background-color: #f4f4f4;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 0.9rem;
}

.rest-api-doc .doc-actions-header .download-postman-btn:hover {
  background-color: #eaeaea;
}

.rest-api-doc .doc-actions-header .language-selector {
  display: flex;
  align-items: center;
  gap: 10px;
}

.rest-api-doc .doc-actions-header .language-selector select {
  border: none;
  cursor: pointer;
  /* padding: 5px 10px; */
}
