.deployments-page {
  display: flex;
  flex-grow: 1;
  width: 100%;
}

.deployment-sidebar {
  width: 200px;
  background: #f0f0f0;
  padding: 20px;
  min-height: 100%;
}

.deployment-sidebar button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: transparent;
  color: #4b7bec;
  text-align: left;
}

.deployment-sidebar button:hover {
  background: #eaeaea;
  color: #3a61c9;
}

.deployment-intro,
.deployment-details {
  flex-grow: 1;
  padding: 20px;
}

.deployment-details {
  display: flex;
}

.deployment-intro {
  background: #f4f4f4;
  border-radius: 8px;
}
