.cors-handler {
  color: #ffffff;
  font-size: 0.9em;
}

.cors-section-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  /* make children all width */
  justify-content: space-between;
}

/* children should be part equal width and both together have parent width */
.cors-section {
  width: calc(25% - 10px);
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}

.cors-section input {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #778ca3;
  background-color: #1e2125;
  color: white;
  margin-bottom: 0;
}

.cors-section button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #26de81;
}

.cors-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 10px;
}

.cors-item {
  background-color: #2a2d32;
  padding: 5px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 5px;
}

/* origin
method
header
exposedHeader */

/* Background colors should be shades of smooth nice blue */
.cors-item.origin {
  background-color: #2a2d32;
}

.cors-item.method {
  background-color: #2a2d32;
}

.cors-item.header {
  background-color: #2a2d32;
}

.cors-item.exposedHeader {
  background-color: #2a2d32;
}

.cors-item button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #eb3b5a;
}

.cors-toggle,
.cors-max-age {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.cors-toggle span,
.cors-max-age span {
  margin-right: 10px;
}

.cors-toggle button,
.cors-max-age input {
  border: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
}

.cors-max-age input {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #778ca3;
  background-color: #1e2125;
}
