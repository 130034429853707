/* Styles pour ApiModelLeftBar */
.api-modal-left-bar {
  width: 250px;
  height: calc(100vh - 53.5px - 59px);
  background-color: #0e1d30 !important; /* Ajusté pour être en harmonie avec LeftBarDesigner */
  color: #b89d5f !important;
  padding: 20px !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1) !important;
  display: flex;
  flex-direction: column;
}

.api-modal-left-bar {
  background-color: #0e1d30;
  color: #ffffff;
  padding: 20px;
  box-shadow: none;
}

.api-modal-left-bar .back-to-models {
  color: #b89d5f;
  margin-bottom: 20px;
  text-decoration: none;
}

.api-modal-left-bar .back-to-models:hover {
  color: #ffd700;
}

.api-modal-left-bar .model-header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.api-modal-left-bar .model-name {
  margin-left: 10px;
  color: #b89d5f;
  font-weight: bold;
}

.api-modal-left-bar .tool-category h4 {
  color: #d4af37;
  margin-bottom: 15px;
}

.api-modal-left-bar .tool-category .tool-element {
  background-color: #1a3148;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.api-modal-left-bar .tool-category .tool-element:hover {
  background-color: #23324d;
}

.api-modal-left-bar .tool-element-label {
  color: #ffffff;
}

.api-modal-left-bar .badge-secondary {
  background-color: #23324d;
  color: #ffffff;
  font-size: 0.8rem;
}

.api-modal-left-bar .config-modal-btn {
  background-color: #d4af37;
  color: #0e1d30;
  border: none;
  padding: 8px 15px;
  margin-bottom: 20px;
  border-radius: 4px;
  font-weight: bold;
  transition: background-color 0.2s;
}

.api-modal-left-bar .config-modal-btn:hover {
  background-color: #ffd700;
}
