.api-model-construction-zone {
  display: flex;
}

.LaunchLite .construction-zone {
  height: calc(100vh - 53.5px - 59px);
  /* border: 2px dashed #cfcfcf; */
  /* margin: 20px; */
  padding: 20px;
  position: relative;
  background: linear-gradient(
    to right,
    #0f1825,
    #1f2f45
  ); /* Adjust the gradient colors to match the site theme */
  color: #ffffff;
  /* border-radius: 10px; */
  width: calc(100vw - 250px - 250px);
}

.LaunchLite .construction-zone:hover {
  /* border-color: gold !important; */
}

/* Styles généraux pour la zone de construction */
.construction-zone {
  background: linear-gradient(to right, #0f1825, #1f2f45);
  color: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow-y: auto;
  /* border-radius: 10px; */
  gap: 15px;
}

/* Style pour les items individuels dans la zone de construction */
.construction-zone .endpoint-item,
.construction-zone .route-item,
.construction-zone .middleware-item {
  background-color: #23324d;
  padding: 10px 15px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  color: white;
  cursor: move;
  width: 100%; /* Les items prennent toute la largeur */
}

/* Styles spécifiques pour EndpointItem */
.endpoint-item {
  border-left: 4px solid var(--orange-2);
}

/* Styles spécifiques pour RouteItem */
.route-item {
  border-left: 4px solid var(--violet-1);
}

/* Styles spécifiques pour MiddlewareItem */
.middleware-item-parent-draggable {
  width: 100%;
}

.middleware-item {
  border-left: 4px solid var(--bg-cyan-1);
}

.construction-zone .endpoint-item.is-dragging,
.construction-zone .route-item.is-dragging,
.construction-zone .middleware-item.is-dragging {
  opacity: 0.5;
}

/* Styles pour les titres et sous-titres des items */
.item-title {
  font-size: 1.1em;
  font-weight: bold;
}

.item-subtitle {
  font-size: 0.9em;
  color: #b89d5f;
}

/* Styles pour les boutons et actions dans les items */
.item-action {
  padding: 5px 10px;
  background-color: var(--orange-2);
  border: none;
  border-radius: 3px;
  color: white;
  font-size: 0.8em;
  cursor: pointer;
}

.item-action:hover {
  background-color: var(--orange-3);
}

/* Ajouts pour l'interaction */
.construction-zone .item:hover {
  transform: scale(1.03);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
}

/* Adaptation pour petits écrans */
@media (max-width: 768px) {
  .construction-zone .item {
    width: 100%; /* Assure que les items s'adaptent à la largeur de petits écrans */
  }
}

.construction-zone .draggable-component {
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  /* margin-bottom: 10px; */
}

.construction-zone .draggable-component:hover {
  /* transform: scale(1.02); */
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); */
}

.construction-zone .draggable-component.is-dragging {
  opacity: 0.5;
}
