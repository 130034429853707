.endpoint-details-container {
  display: flex;
  /* #1e2125 */
  background: #202833;
  padding: 20px;
  border-radius: 8px;
  color: #fff;
  margin-top: 20px;
}

.request-side,
.response-side {
  flex: 1;
  flex-basis: 0;
  /* margin-right: 20px; */
  min-width: 0; /* Empêche l'élément de devenir plus petit qu'une certaine taille */
  max-width: 50%;
  display: flex;
  flex-direction: column;
}

h5 {
  color: #4b6584;
  margin-bottom: 15px;
  margin-top: 0;
}

.request-details,
.response-details {
  /* 2a2d32 */
  background: #252c39;
  padding: 15px;
  border-radius: 8px;
  flex-grow: 1;
}

.response-details > p {
  margin-bottom: 0;
}

.request-line {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

/* last child request line has no margin-bottom */
.request-line:last-child {
  margin-bottom: 0;
}

.method {
  background: #20bf6b;
  padding: 6px 12px;
  border-radius: 4px;
  margin-right: 10px;
  font-weight: bold;
}

.method.get {
  background: #20bf6b;
  color: #fff;
}

.method.post {
  background: #4b7bec;
  color: #fff;
}

.method.put {
  background: #8854d0;
  color: #fff;
}

.method.delete {
  color: #fff;
  background: #eb3b5a;
}

.url {
  color: #d1d8e0;
  width: 100%;
  overflow-x: auto;
  display: block;
  white-space: nowrap;
}

.params-table {
  width: 100%;
  border-collapse: collapse;
}

.params-table td {
  padding: 8px;
  border-top: 1px solid #3b4045;
  color: #d1d8e0;
}

.json-body {
  background: #23272a;
  padding: 15px;
  border-radius: 5px;
  color: #d1d8e0;
  overflow-x: auto;
  margin-bottom: 0;
}

.json-body::-webkit-scrollbar {
  height: 6px;
}

.json-body::-webkit-scrollbar-thumb {
  background: #4b6584;
  border-radius: 10px;
}

.response-details p {
  font-size: 1em;
  color: #d1d8e0;
}

/* Style supplémentaire pour un effet visuel moderne */
.endpoint-details-container {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Style pour séparer visuellement la requête et la réponse */
.request-side {
  border-right: 1px solid #3b4045;
  padding-right: 20px;
}

.response-side {
  padding-left: 20px;
}

.endpoint-details-container.lite {
  background: #fff;
  color: #000;
}

.endpoint-details-container.lite .request-details,
.endpoint-details-container.lite .response-details {
  background: #f5f5f5;
  color: #000;
}

.endpoint-details-container.lite .url {
  color: #fff;
  /* background: #4b6584; */
  /* background: #252c39; */
  background: rgba(37, 44, 57, 0.9);
  padding: 6px 12px;
  border-radius: 4px;
}

.endpoint-details-container.lite .params-table td {
  border-top: 1px solid #ccc;
  color: #000;
}

.endpoint-details-container.lite .json-body {
  /* background: #f0f0f0;
  color: #000; */
  color: #fff;
  background: rgba(37, 44, 57, 0.9);
}

.endpoint-details-container.lite .json-body::-webkit-scrollbar-thumb {
  background: #000;
}

.endpoint-details-container.lite .response-details p {
  color: #000;
}

.endpoint-details-container.lite .request-side {
  border-right: 1px solid #ccc;
}

.endpoint-details-container.lite .response-side {
  padding-left: 20px;
}
