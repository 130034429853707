.token-group-manager {
  /* max-height: 300px; */
  overflow-y: auto;
  background-color: #23324d;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  color: #ffffff;

  /* parent is flex and height should fill empty space in parent */
  flex-grow: 1;
}

.token-group-manager h4 {
  margin-bottom: 15px;
  color: #d4af37;
  font-size: 1.2em;
}

.token-group {
  background-color: #2a2d32;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.token-group .group-name {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.token-group input,
.token-group select {
  width: 100%;
  padding: 8px;
  margin-bottom: 5px;
  border-radius: 4px;
  border: 1px solid #778ca3;
  background-color: #1e2125;
  color: #ffffff;
}

.token-group input::placeholder,
.token-group select {
  color: #bfbfbf;
}

.token-group-manager button {
  width: 100%;
  padding: 10px;
  background-color: #4b7bec;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.token-group-manager button:hover {
  background-color: #3a61c9;
}

.token-group-api-detail {
  margin-bottom: 10px;
}

.token-group-toggle-button-header {
  background: #4b7bec;
  padding: 10px;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
  margin-bottom: 10px;
}

.token-group-api-detail.active .token-group-toggle-button-header {
  background: #3a61c9;
}

/* Modern style for button header in lite mode, note: it will be lite mode but to be on a lite background too. So it must not be white */
.token-groups-api-details.lite .token-group-toggle-button-header {
  background: #fff; /* Set a modern white-grey background color */
  color: #333; /* Set the text color to a darker shade of grey */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Add a nice and smooth box-shadow */
}

.token-groups-api-details.lite
  .token-group-api-detail.active
  .token-group-toggle-button-header {
  background: #f5f5f5; /* Set a modern white-grey background color */
}
