/* .method-rules-script-editor_container {
  height: 300px;
  border: 1px solid var(--gray-color);
  border-radius: 4px;
  margin-bottom: 20px;
  background-color: var(--bg-dark-modal-body-1);
}

.method-rules-script-editor_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: var(--bg-dark-modal-header);
  border-bottom: 1px solid var(--gray-color);
} */

/* Style pour CodeMirror (si besoin de surcharger) */
/* .method-rules-script-editor_container .cm-theme {
  --cm-background: var(--bg-dark-modal-body-1);
  --cm-color: var(--white-color);
  --cm-gutterBackground: var(--bg-dark-item-2);
  --cm-gutterBorder: var(--gray-color);
} */

/* Assurez-vous que le thème sublime est bien appliqué et ajustez selon vos besoins */
/* .method-rules-script-editor_container .CodeMirror {
  font-family: "Monaco", "Menlo", "Ubuntu Mono", "Consolas", "source-code-pro",
    monospace;
  font-size: 14px;
  height: auto;
  color: var(--white-color);
}

.method-rules-script-editor_container .CodeMirror-lines {
  padding: 10px 0;
} */

/* Couleurs personnalisées pour les tokens JavaScript (facultatif) */
/* .method-rules-script-editor_container .cm-s-sublime .cm-keyword {
  color: var(--violet-color);
}
.method-rules-script-editor_container .cm-s-sublime .cm-string {
  color: var(--success-color);
}
.method-rules-script-editor_container .cm-s-sublime .cm-number,
.cm-s-sublime .cm-atom {
  color: var(--orange-color);
}
.method-rules-script-editor_container .cm-s-sublime .cm-variable {
  color: var(--info-color);
}
.method-rules-script-editor_container .cm-s-sublime .cm-variable-2 {
  color: var(--warning-color);
}
.method-rules-script-editor_container .cm-s-sublime .cm-def {
  color: var(--primary-color);
}
.method-rules-script-editor_container .cm-s-sublime .cm-type {
  color: var(--success-color);
}
.method-rules-script-editor_container .cm-s-sublime .cm-comment {
  color: var(--gray-color);
}
.method-rules-script-editor_container .cm-s-sublime .cm-operator {
  color: var(--pink-color);
} */

/* Style below is based from style above */

/* Tooltip.css */
.tooltip-container-cx {
  position: relative;
  display: inline-block;
}

.tooltip-container-cx .tooltip-message {
  visibility: hidden;
  width: 120px;
  background-color: #fff;
  color: #000;
  text-align: center;
  border-radius: 2px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  /* bottom: 125%; */ /* original */
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  /* light box shadow , cloud services style */
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.tooltip-container-cx:hover .tooltip-message {
  visibility: visible;
  opacity: 1;
}
