.LaunchLite {
  padding: 0 !important;
}

.LaunchLite .left-bar {
  width: 250px;
  height: calc(100vh - 53.5px - 59px);
  background-color: #0e1d30 !important; /* Ajusté pour être en harmonie avec LeftBarDesigner */
  color: #b89d5f !important;
  padding: 20px !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1) !important;
}

.LaunchLite .left-bar h5 {
  color: #d4af37 !important;
  margin-bottom: 20px !important;
}

.LaunchLite .left-bar .list-group-item {
  background-color: #0e1d30 !important; /* Ajusté pour être en harmonie avec LeftBarDesigner */
  color: #b89d5f !important;
  border: none !important;
}

.LaunchLite .left-bar .list-group-item:hover {
  background-color: #0c1b2d !important; /* Ajusté pour être en harmonie avec system-designer-header */
}

.system-designer-header a.active {
  color: #d4af37 !important;
}

.system-designer-header a,
.system-designer-header a:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.LaunchLite .left-bar {
  background-color: #0e1d30 !important;
  color: white !important;
}

.LaunchLite .left-bar .back-to-models {
  color: gold !important;
  font-weight: 500;
}

.LaunchLite .left-bar .back-to-models:hover {
  color: #ffd700 !important;
  text-decoration: none;
}

.LaunchLite .tool-category h4 {
  color: gold !important;
  border-bottom: 2px solid gold;
  display: inline-block;
  padding-bottom: 5px;
}

.LaunchLite .tool-category .tool-element {
  background-color: #1a3148 !important;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: grab;
}

.LaunchLite .tool-category .tool-element:hover {
  background-color: #23324d !important;
}

.LaunchLite .tool-element-label {
  color: white !important;
}

.deployment-models-list .deployment-model-type.api {
  background-color: var(--blue-color) !important;
}

.deployment-models-list .deployment-model-type.interface {
  background-color: var(--green-color) !important;
}
