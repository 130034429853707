.endpoint-item {
  background-color: #23324d;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.endpoint-item.is-dragging {
  /* opacity: 0.5; */
}

.endpoint-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.endpoint-header .endpoint-base-info {
  display: flex;
  gap: 10px;
}

select:focus {
  outline: none;
}

.endpoint-base-info .endpoint-input,
.endpoint-base-info .method-selector,
.selectors select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background-color: transparent;
  color: #e1e1e1;
  font-size: 1em;
}

.endpoint-base-info .method-selector,
.selectors select {
  /* border: none; */
}

.endpoint-input::placeholder,
.selectors select {
  color: #bfbfbf;
}
.selectors {
  display: flex;
  gap: 10px;
}

.tag {
  display: inline-block;
  padding: 5px;
  margin-right: 5px;
  border-radius: 10px;
  font-size: 0.9em;
  margin-top: 5px;
  cursor: pointer;
}

.input {
  background-color: #26de81;
}
.output {
  background-color: #eb3b5a;
}
.listener {
  background-color: #45aaf2;
}

.promptBlock {
  background-color: #a55eea;
}
.inputBlock {
  background-color: #fed330;
}
.emitterFetch {
  background-color: #2bcbba;
}
.emitterLogic {
  background-color: #778ca3;
}
.inputConnection {
  background-color: #4b7bec;
}
.globalParameter {
  background-color: #8854d0;
}

.tag.deactivated .compute-block-icon {
  color: #26de81;
}

.tag.activated .compute-block-icon {
  color: #d1d8e0;
  opacity: 0.5;
}

.tag .compute-block-icon {
  width: 7px;
  height: 7px;
  margin-right: 5px;
}

.endpoint-details .tag-container {
  display: flex;
  flex-wrap: wrap;
}

.middlewares-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
}

.middleware-tag {
  background-color: #2d98da;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  display: inline-flex;
  align-items: center;
}

.middleware-name {
  margin-right: 5px;
}
